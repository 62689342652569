import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Three countries, three days of racing. The famous
`}<a parentName="p" {...{
        "href": "http://www.3laenderenduro.com"
      }}>{`3 Länder Enduro Race`}</a>{` at the boarder triangle
of Italy, Switzerland and Austria takes place from the 24. - 26.08.2018.
Booked out after a couple of minutes, you now can win two tickets!`}</p>
    <br />
    <p><strong parentName="p">{`How to win?`}</strong>{` Like our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook page`}</a>{`
and comment the post (about the draw). The prize is a full three day start
package including the start number, timing, lift and shuttles and lunch and
dinner at the race. The winners are drawn on Tuesday evening the 14th of
August on Facebook.`}</p>
    <br />
    <p>{`Scroll down to get an impression of the area around the Reschenpass.`}</p>
    <TrailguidePlugin content="lat=46.8260&lng=10.5192&coverage=10" mdxType="TrailguidePlugin" />
    <p>{`Good luck for the drawing and we are looking forward to see you in two weeks!`}</p>
    <br />
    <Image src="destinations/reschen_1.jpg" mdxType="Image" />
    <p>{`Roots, rocks and good speed. Find the Haideralm trail
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1954"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_3.jpg" mdxType="Image" />
    <p>{`Our favourite one. The lakes at the pass look through the trees of the
Haideralm. Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_4.jpg" mdxType="Image" />
    <p>{`So many roots and cool corners. Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_5.jpg" mdxType="Image" />
    <p>{`Some natural jumps here and there on the Spin Trail.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1938"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_2.jpg" mdxType="Image" />
    <p>{`Also on the other trails there are often natural opportunities to catch
some air. Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1954"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_9.jpg" mdxType="Image" />
    <p>{`On the way up to the 3-Länder Trail at the border triangular of Switzerland,
Austria and Italy, you shouldn't miss the Reschner Alm. Find the trail
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_10.jpg" mdxType="Image" />
    <p>{`At the border triangular a long descent starts down to Nauders.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_11.jpg" mdxType="Image" />
    <p>{`The trail passes some idyllic places. Find the trail
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1937"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/reschen_7.jpg" mdxType="Image" />
    <p>{`Similar to the Haideralm Trail, the Schöneben trail with many roots and
grippy forest floor. One of our favourites. Find the trail
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1936"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      